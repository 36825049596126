<template>
  <div class="login">
    <main-titlebar />
      <div class="trip-view-wrapper">  
      <div class="grid-box-vscale">
        <p>
          <img id="profile-img" :src="logo" class="profile-img-card" alt="TripTrader Logo"/>
        </p>
        <p>Welkom bij TripTrader</p>
      </div>
      <Form id="loginForm" @submit="handleLogin" :validation-schema="schema">

        <div class="trip-view-row">
          <div class="trip-view-col">
            <div class="form-group">     
              <Field name="username" type="text" class="form-input" placeholder="Gebruikersnaam/email"/>
            </div>
          </div>
        </div>
        <div class="trip-view-row">
            <div class="form-group textAlignCenter" >          
              <ErrorMessage name="username" class="error-feedback" />
            </div>
        </div>
  


        <div class="trip-view-row">
          <div class="trip-view-col">
            <div class="form-group">     
            <Field name="password" type="password" class="form-input" placeholder="Wachtwoord"/>
            </div>
          </div>
        </div>
        <div class="trip-view-row">

            <div class="form-group">          
            <ErrorMessage name="password" class="error-feedback" />  
            </div>
  
        </div>



      <div class="form-group">
        <div v-if="message" class="alert alert-danger" role="alert">
            {{ message }}
        </div>
      </div>
      <div class="bottomBar">
        <div class="col-2 textAlignLeft">
          <div class="bottomBar-button"  @click="registerUser" :disabled="loading">Registreren</div>
          
        </div>
        <div class="col-2 textAlignRight alignRight">
          <button class="bottomBar-button" :disabled="loading">
          <span>Aanmelden</span>
          </button>
        </div>
      </div>
      </Form>
      </div>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
export default {
  name: "Login",
  components: {
    'main-titlebar': require('@/components/titlebar.vue').default,
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      username: yup.string().required("Geen gebruikersnaam opgegeven"),
      password: yup.string().required("Geen wachtwoord opgegeven"),
    });
    return {
      logo:require('../../assets/triptrader-logo.png'),
      loading: false,
      message: "",
      schema,
    };
  },
  computed: {
 
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/");
    }
  },
  methods: {
    registerUser() {
      this.$router.push('/register');
    },
    handleLogin(user) {
      this.loading = true;
      this.$store.dispatch("auth/login", user).then(
        () => {
          this.$router.push("/");
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
};
</script>